import React, { useEffect, useRef, useState } from 'react';
import { StreamPixelApplication } from 'streampixel';
import axios from 'axios';
import {  FaVolumeUp, FaVolumeMute, FaExpand, FaCompress, FaInfoCircle  } from 'react-icons/fa'; 
import ResolutionModalButton from './ResolutionModalButton';
import { isMobile,isTablet,isDesktop,useMobileOrientation } from 'react-device-detect';

let PixelStreamingApp;
let PixelStreamingUiApp;
let lastTimeResized = 0;
let activeResolution;
let resolutionSetting = [];

let message;
const resolutionDef = [
  "360p (640x360)",
  "480p (854x480)",
  "720p (1280x720)",
  "1080p (1920x1080)",
  "1440p (2560x1440)",
  "4K (3840x2160)"
];

let maxHeight;
let maxWidth;
let unrealVersion = 5.2;
let videoResolution;


const App = () => {
  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [dpr, setDpr] = useState(window.devicePixelRatio);
  const videoRef = useRef(null);
  const hasStartedPlay = useRef(false);
  const audioRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false); 
  const [isMuted, setIsMuted] = useState(true); 
  const [keyStates, setKeyStates] = useState({ t: false, five: false });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stats, setStats] = useState(null);
  const [status, setStatus] = useState(null);
  const [delayTime, setDelayTime] = useState(20000);
  const [showBtn, setShowBtn] = useState(true);
  const [showBtnResolution, setShowBtnResolution] = useState(true);

  const [showMic, setShowMic] = useState(false);
  const [showBtnInfo, setShowBtnInfo] = useState(false);

  const [passwordValid, setPasswordValid] = useState(false);

  const [showEnterPassword, setShowEnterPassword] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');
  const [project, setProject] = useState();
  const [domainValid, setDomainValid] = useState(false);
  const [parentUrl, setParentUrl] = useState("share.streampixel.io");
  const [loading, setLoading] = useState(true);
  const [allValid, setAllValid] = useState(false);
  const [fetchDone, setFetchDone] = useState(false);
  const [resolutionMode, setResolutionMode] = useState();
  const [maxStreamQuality, setMaxStreamQuality] = useState();
  const [startResolution, setStartResolution] = useState();
  const [startResolutionMobile, setStartResolutionMobile] = useState();

  const [startResolutionTab, setStartResolutionTab] = useState();

  const [defWidth, setDefWidth] = useState();
  const [defHeight, setDefHeight] = useState();



  const [uiBtn, setShowUiBtn] = useState(false);

  const [projectId, setProjectId] = useState(null); 
  

  const mobOr =  useMobileOrientation();

  function extractHost(url) {
    const pattern = /^(?:https?:\/\/|www:\/\/)?([^/]+)/;
    
    const match = url.match(pattern);
    
    if (match) {
      return match[1]; 
    }
    return null; 
  }


  useEffect(() => {
    const handleClickOutside = () => {
      if (isModalVisible) {
        setIsModalVisible(false);
      }
    };

    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalVisible]);

  const urlPart = window.location.href.split('/').pop();
  useEffect(() => {
    if (urlPart) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const pwdValue = urlSearchParams.get('pwd');
      const baseId = urlPart.split('?')[0];
      
      const frameUrl = window.frames.location.ancestorOrigins[0];


      if(frameUrl){

        
        const domain = extractHost(frameUrl);

        setParentUrl(domain);

        message = {
          "type": "stream-state",
          "value": "authenticating"
        }

        window.parent.postMessage(message, "*");
      }
       
      if (urlPart.startsWith('share-')) {
        showLoadingSpinner();
        fetchShareProjectDetails(baseId);
      } else {
        showLoadingSpinner();

        setProjectId(baseId);
      }

      if(pwdValue){
      setEnteredPassword(pwdValue);
      handlePasswordSubmitUrl(pwdValue,baseId) ; 

     
    }
    } else {
      setStatus('invalidurl');
      setLoading(false);
    }

  }, [urlPart]);



  const getStats = () => {
    PixelStreamingUiApp.statsPanel.show();
    const statsElement = PixelStreamingUiApp.statsPanel._statsResult;
  
    if (!statsElement) {
      return {}; 
    }
  
    const statDivs = statsElement.querySelectorAll('div');
  
    if (!statDivs.length) {
      return {}; 
    }
  
    const stats = {};
  
    statDivs.forEach((div) => {
      const text = div.textContent.trim(); 
  
      
      if (text.length === 0) {
        return;
      }
  
      const [key, value] = text.split(':').map(item => item.trim());
  


      if (key && value && key.toLowerCase() !== 'players') {
        stats[key] = value;

        if(key == "Video resolution"){
        videoResolution =  value;
        }

      }
    });
  
    return stats;
  };
  
  const fetchShareProjectDetails = async (shareId) => {
    try {
      const response = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/shareId/${shareId}`);
      if (response.status === 200) {
        const { _id: projectId } = response.data;
       
        setProjectId(projectId); 
      }
    } catch (error) {
      console.error('Error fetching project details via shareId:', error);
      setStatus('error');
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`);
      if (response.status === 200) {
        const { passwordProtect, delay, showBtn, showBtnInfo, showMic, status: projectStatus,validPathUrl,showBtnResolution, resolution,resolutionMode,maxStreamQuality,startResolution, startResolutionTab,startResolutionMobile,files,appName } = response.data;

         setFetchDone(true);
        setResolutionMode(resolutionMode);

        const appFileIndex = files.findIndex(file => file.appPath === appName);
    
        if (appFileIndex !== -1) {
           const unrealVersionString = files[appFileIndex] && files[appFileIndex].unrealVersion;
        
           unrealVersion = parseFloat(unrealVersionString);

        }

        
        setMaxStreamQuality(maxStreamQuality);
        setShowBtnResolution(showBtnResolution);

        let resIndex = resolutionDef.findIndex(resolution => resolution === maxStreamQuality);
     
        resolutionSetting = resolutionDef.slice(0,resIndex+1);


        setStartResolution(startResolution);
        setStartResolutionMobile(startResolutionMobile);
        setStartResolutionTab(startResolutionTab);

        if(isMobile){
          var startResmatch = startResolutionMobile.match(/\((\d+x\d+)\)/);

          if (startResmatch) {
          activeResolution = startResolutionMobile;

          var hwsplit = startResmatch[1].split('x');
          maxHeight = hwsplit[1];
          maxWidth = hwsplit[0];

          }
        }
        if(isTablet){
          var startResmatch = startResolutionTab.match(/\((\d+x\d+)\)/);

          if (startResmatch) {
          activeResolution = startResolutionTab;

          var hwsplit = startResmatch[1].split('x');
          maxHeight = hwsplit[1];
          maxWidth = hwsplit[0];

          }
          
        }
        if(isDesktop){
          var startResmatch = startResolution.match(/\((\d+x\d+)\)/);

          if (startResmatch) {

          activeResolution = startResolution;
          var hwsplit = startResmatch[1].split('x');
          maxHeight = hwsplit[1];
          maxWidth = hwsplit[0];
          
        }
      }
        if(resolution == false){
          const videoElement = document.querySelector('video');
    
          if (videoElement) {
              videoElement.style.objectFit = 'contain';
          }
        }

        if(passwordProtect == true){
          setLoading(false);
  const spinner = document.querySelector('.loading-spinner');
   if (spinner) {
      spinner.style.display = 'none'; 
      spinner.remove();
  }
     
        }

        if (validPathUrl && validPathUrl.length > 0) {
          if (parentUrl) {
            let isValid = false;
        
            for (let url of validPathUrl) {
              if (url.includes('*')) {
                let domainPart = url.split('*')[1];
        
                if (parentUrl.includes(domainPart)) {
                  isValid = true;
                  break;
                }
              } else {
                if (url.includes(parentUrl)) {
                  isValid = true;
                  break;
                }
              }
            }
        
            setDomainValid(isValid);
          }
        } else {
          setDomainValid(true);
        }

        setShowBtnInfo(showBtnInfo);
        setShowEnterPassword(passwordProtect);
        setProject(response.data);
        setDelayTime(delay);
        setShowBtn(showBtn);
        setShowMic(showMic);
        setStatus(projectStatus ? 'active' : 'inactive');
      }
    } catch (error) {
      console.error('Error fetching project details:', error);
      setStatus('error');
    }
  };


useEffect(()=>{
if(loading == false){
  hideLoadingSpinner();
}
},[loading])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key.toLowerCase() === 't') setKeyStates((prev) => ({ ...prev, t: true }));
      if (event.key === '5') setKeyStates((prev) => ({ ...prev, five: true }));
    };

    const handleKeyUp = (event) => {
      if (event.key.toLowerCase() === 't') setKeyStates((prev) => ({ ...prev, t: false }));
      if (event.key === '5') setKeyStates((prev) => ({ ...prev, five: false }));
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (keyStates.t && keyStates.five) {
     
      if (PixelStreamingUiApp) {
        const statsData = getStats();
        setStats(statsData);
        setIsModalVisible((prev) => !prev);
      }
    }
  }, [keyStates]);

  useEffect(() => {
    if (resolutionMode == "Dynamic Resolution Mode") {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [resolutionMode]);



  const handleResize = ()=>{

    let viewportH = Math.floor(window.innerHeight* window.devicePixelRatio); 
    let viewportW = Math.floor(window.innerWidth* window.devicePixelRatio);
    let maxPixel = Math.floor(maxHeight*maxWidth);
    let viewportPixel = viewportH*viewportW;


    if(maxPixel > viewportPixel){

      let resolution = viewportW+"x"+viewportH+"f";
      handleResolution(resolution);

    }else{

    let divideNumber = Math.min(maxWidth/viewportW,maxHeight/viewportH);


    let resW = Math.floor(divideNumber * viewportW );
    let resH = Math.floor(divideNumber * viewportH );
    let resolution = resW+"x"+resH+"f";

   
  
    handleResolution(resolution);
    }

  }
  


  const handleResolution = (resolution) => {

    const now = Date.now();
    const timeSinceLastResize = now - lastTimeResized;
  
    if (timeSinceLastResize > 3000) {
      if (PixelStreamingUiApp) {
        PixelStreamingUiApp.stream.emitConsoleCommand(`r.SetRes ${resolution}`);
      }
  
      lastTimeResized = now;
    } 
  };
  
  

  useEffect(() => {

    if (!hasStartedPlay.current && status === 'active' && !showEnterPassword && domainValid) {
      setAllValid(true);
      hasStartedPlay.current = true;
      
   
      const timeoutId = setTimeout(() => {
        startPlay();
     
          setLoading(false);
        
       
      }, 2000);
  
      return () => clearTimeout(timeoutId);
    } else(
      setTimeout(()=>{
if(fetchDone){
  hideLoadingSpinner();
  setLoading(false);

  message = {
    "type": "stream-state",
    "value": "restricted"
  }
  window.parent.postMessage(message, "*");

}
  },15000)
    )
  }, [status, showEnterPassword, domainValid,passwordValid,fetchDone]);
  

  const startPlay = async () => {
    
    const { appStream, pixelStreaming } = StreamPixelApplication({
      AutoPlayVideo: true,
      StartVideoMuted: true,
      AutoConnect: true,
      useMic: project.showMic,
      appId: projectId,
      unrealVersion:unrealVersion,
      afktimeout:project.afktimeout,
      touchInput:project.touchInput,
      mouseInput:project.mouseInput,
      gamepadInput:project.gamepadInput,
      resolution:project.resolution,
      hoverMouse:project.hoverMouse,
      xrInput:project.xrInput,
      keyBoardInput:project.keyBoardInput,
    });

    PixelStreamingApp = pixelStreaming;
    PixelStreamingUiApp = appStream;

    PixelStreamingApp.addResponseEventListener('handle_responses', handleResponseApp);



    videoRef.current.append(appStream.rootElement);

    const videoElement = appStream && appStream.stream.videoElementParent.querySelector("video");
    if(videoElement){
      videoElement.muted = false;
      videoElement.focus();
      videoElement.autoplay = true;
      videoElement.tabIndex = 0;
     
    }
   
    appStream.onDisconnect = function() {
      message = {
        "type": "stream-state",
        "value": "disconnected"
      }
      window.parent.postMessage(message, "*");

    }


    appStream.onVideoInitialized = function() {
      message = {
        "type": "stream-state",
        "value": "loadingComplete"
      }

      window.parent.postMessage(message, "*");

     
      if(resolutionMode == "Fixed Resolution Mode"){

        if (videoElement) {
            videoElement.style.objectFit = 'contain';
        }
       
        let resolution = maxWidth+"x"+maxHeight+"f";
        handleResolution(resolution);
      }
      if (resolutionMode == "Dynamic Resolution Mode") {

        if (videoElement.readyState == 4) {

       
          const intervalId = setInterval(function() {
            if (videoElement.currentTime > 0) {
                getStats();
        
                if (videoResolution && videoResolution.includes('x')) {
                    handleResize();
                }
            }
        }, 600);

        setTimeout(() => {
          clearInterval(intervalId);
      }, 15000);
          
        }
      }

      if(resolutionMode == "Crop on Resize Mode"){

      const vpele =document.getElementById("streamingVideo");
      
      vpele.removeAttribute('style');

      if (videoElement.readyState == 4) {
  
        const intervalId = setInterval(function() {
          if (videoElement.currentTime > 0) {
              getStats();
      
              if (videoResolution && videoResolution.includes('x')) {
                  handleResize();
              }
          }
      }, 600);

      setTimeout(() => {
        clearInterval(intervalId);
    }, 15000);
      }


      }

      setShowUiBtn(true);
      setLoading(false);

       const spinner = document.querySelector('.loading-spinner');


       if (spinner) {
           spinner.remove();
       }

       hideLoadingSpinner();
    }

 

    if(project.showMic == true){
    
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          pixelStreaming.unmuteMicrophone(true);
        } catch (err) {
          console.error('Microphone access denied', err);
        }

    }
  
    addMessageListener();
  };




  const handleResponseApp = (response) => {
    if (response.includes('https')) {
      window.open(response);
    }
    window.parent.postMessage(response, '*');
  };

  const addMessageListener = () => {
    window.addEventListener('message', (event) => {
      if(PixelStreamingUiApp){
      const audioElement = PixelStreamingUiApp.stream._webRtcController.streamController.audioElement;
      
      const { message } = event.data;


      if(message === 'heartbeat'){
        
        document.getElementById("afkOverlayInner").click();

      }
      
      if(message && message.type == 'setResolution'){

        if(resolutionSetting.includes(message.value)){
          
const match = message.value.match(/\((\d+x\d+)\)/);

if (match) {

handleResMax(match[1],message.value);


}
          activeResolution = message.value; 

        }
      }

      if (message === 'terminateSession') {
        handleTerminateSession();
      } 
       if (message === 'muteAudio') {
       
          audioElement.pause();
          audioElement.muted = !isMuted;
        
      }
      if(message === 'unMuteAudio'){

        audioElement.play();
        audioElement.muted = !isMuted;

      }
    
      handleSendCommand(event.data);
    }
    });
    
  };

  const handleTerminateSession = () => {
    PixelStreamingApp.disconnect();
    PixelStreamingUiApp?.stream.disconnect();
  };

  const handleSendCommand = (descriptor) => {
    PixelStreamingUiApp?.stream.emitUIInteraction(descriptor);
  };

  const handlePasswordSubmitUrl = async (pwd,baseId) => {
    try {
      const res = await fetch('https://api.streampixel.io/pixelStripeApi/projects/passwordCheck', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: pwd, projectId:baseId }),
      });

      if (res.status === 200) {
        setPasswordValid(true);
        setLoading(true);

        setShowEnterPassword(false);
      } else {
        alert('Invalid password');
        setPasswordValid(false);
      }
    } catch (error) {
      console.error('Error validating password:', error);
      alert('Error validating password. Please try again.');
      setPasswordValid(false);
    }
  };

  const handlePasswordSubmit = async () => {
   
    try {
      const res = await fetch('https://api.streampixel.io/pixelStripeApi/projects/passwordCheck', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: enteredPassword, projectId }),
      });

      if (res.status === 200) {
        setPasswordValid(true);
        showLoadingSpinner();
        setShowEnterPassword(false);
      } else {
        alert('Invalid password');
        setPasswordValid(false);
        setLoading(false);
        hideLoadingSpinner();
      }
    } catch (error) {
      console.error('Error validating password:', error);
      alert('Error validating password. Please try again.');
      setPasswordValid(false);
    }
  };

  const toggleSound = () => {

    const videoPlayer = PixelStreamingUiApp && PixelStreamingUiApp.stream.videoElementParent.querySelector("video");


    if (PixelStreamingUiApp) {
        const audioElement = PixelStreamingUiApp.stream._webRtcController.streamController.audioElement;
        audioElement.play();
        audioElement.muted = !isMuted;
        setIsMuted(!isMuted);
        if (videoPlayer) {
          videoPlayer.focus();
        }
     
/*
        const audioTracks = audioElement.srcObject?.getAudioTracks(); // Get audio tracks
        if (audioTracks && audioTracks.length > 0) {

          console.log(audioTracks[0])
            audioTracks[0].enabled = !audioTracks[0].enabled; // Toggle mute/unmute the first audio track
            setIsMuted(!audioTracks[0].enabled); // Update the state after toggling
            console.log(`Audio track ${audioTracks[0].enabled ? 'unmuted' : 'muted'}.`);
        } else {
       
            console.warn("No audio tracks found in the audio stream.");
        }
      */      
     }
        
  }   

  const toggleFullscreen = () => {
    const doc = document.documentElement;
    const videoPlayer = PixelStreamingUiApp && PixelStreamingUiApp.stream.videoElementParent.querySelector("video");
  
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  
    if (!document.fullscreenElement && !isIOS) {
      if (doc.requestFullscreen) {
        doc.requestFullscreen();
      } else if (doc.mozRequestFullScreen) { 
        doc.mozRequestFullScreen();
      } else if (doc.webkitRequestFullscreen) { 
        doc.webkitRequestFullscreen();
      } else if (doc.msRequestFullscreen) {
        doc.msRequestFullscreen();
      }
  
      setIsFullscreen(true);
      if (videoPlayer) {
        videoPlayer.focus();
      }
    } else if (isIOS) {
      if (videoPlayer && videoPlayer.webkitEnterFullscreen) {
        videoPlayer.webkitEnterFullscreen();
        setIsFullscreen(true);
      } else {
        console.warn("Fullscreen not supported on this iOS device.");
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { 
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { 
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { 
        document.msExitFullscreen();
      }
  
      setIsFullscreen(false);
      if (videoPlayer) {
        videoPlayer.focus();
      }
    }
  };
  
  
function showLoadingSpinner() {
  const spinner = document.createElement('div');
  spinner.classList.add('loading-spinner');
  spinner.style.position = 'absolute';
  spinner.style.top = '35%';
  spinner.style.left = '50%';
  spinner.style.transform = 'translate(-50%, -50%)';
  spinner.style.width = '60px';
  spinner.style.height = '60px';
  
  // SVG animation
  spinner.innerHTML = `
      <svg width="60" height="60" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="40" stroke="#3498db" stroke-width="8" fill="none" />
          <circle cx="50" cy="50" r="40" stroke="#f3f3f3" stroke-width="8" fill="none" stroke-dasharray="251" stroke-dashoffset="50" transform="rotate(-90 50 50)">
              <animate attributeName="stroke-dashoffset" values="251;0" dur="1s" repeatCount="indefinite"/>
          </circle>
      </svg>
  `;

  document.body.appendChild(spinner);
}

function hideLoadingSpinner() {

  const spinner = document.querySelector('.loading-spinner');


  if (spinner) {
      spinner.remove();
  }
}

const toggleStats = ()=>{


  if (PixelStreamingUiApp) {
    const statsData = getStats();
    setStats(statsData);

    setIsModalVisible((prev) => !prev);
  }

}


const handleResMax =(value,resolution)=>{

  const hwsplit = value.split('x');

  activeResolution = resolution;
 if(resolutionMode == "Dynamic Resolution Mode" || resolutionMode == "Crop on Resize Mode"){
   maxHeight = hwsplit[1];
   maxWidth  = hwsplit[0];
   handleResize();
 }else{
        
  if (PixelStreamingUiApp) {

    const videoElement = document.querySelector('video');

    var resolutionNew = value+'f';
    PixelStreamingUiApp.stream.emitConsoleCommand(`r.SetRes ${resolutionNew}`);
    videoElement.focus();

  }
 }
}

  const renderContent = () => {
    
      return (
        
        <>


        {loading &&(
          <div className='containMain'>
               
            <p style={{textAlign:'center',color:"#fff",fontSize:"1.8em",textTransform:"uppercase"}}>authenticating details</p>

</div>

        )}
{allValid ?(

<div className='containMain'>
   
<div id="videoElement" ref={videoRef}>



   {uiBtn == true ?(
    <div style={styles.controlPanel}>
    <>      
    {project && project.showBtnResolution &&(
    <ResolutionModalButton activeResolution={activeResolution} PixelStreamingUiApp={PixelStreamingUiApp} resolutionSetting={resolutionSetting} handleResMax={handleResMax}/>
    )}

    {project && project.showBtnInfo &&(
    <button style={styles.button} onClick={toggleStats}>
      <FaInfoCircle size={20} />
    </button>
    )}

     {project && project.showBtnVolume &&(
    <button style={styles.button} onClick={toggleSound}>
      {isMuted ? <FaVolumeMute size={20} /> : <FaVolumeUp size={20} />}
    </button>
   )}
    {project && project.showBtn &&(
    <button style={styles.button} onClick={toggleFullscreen}>
      {isFullscreen ? <FaCompress size={20} /> : <FaExpand size={20} />}
    </button>
    )}
    </>
    </div>

   ):( null)} 
  
</div>

</div>

):(
  <>
  {loading == false &&(

<div className='containMain'>
<p style={{textAlign:'center',color:"#fff",fontSize:"1.8em",textTransform:"uppercase"}}>not allowed</p>
</div>


  )}
</>
)}
</>
      )   
       
  };

  return (
    <>
      {!showEnterPassword || passwordValid ? renderContent() : (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background:"black" }}>
          <input
            type="password"
            placeholder="Enter Password"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
            style={{ padding: '10px', fontSize: '16px', marginBottom: '10px' }}
          />
          <button onClick={handlePasswordSubmit} style={{ padding: '10px 20px', fontSize: '16px' }}>Submit</button>
        </div>
      )}

      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Statistics</h2>
            <button className="close-button" onClick={() => setIsModalVisible(false)}>X</button>
            <div className="stats-container">
              {stats && Object.entries(stats).map(([key, value]) => (
                <div key={key} className="stat-item">
                  <strong>{key}:</strong> {value}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;

const styles = {
  controlPanel: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    gap: '10px',
    zIndex:"200"
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
  },
};