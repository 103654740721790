import React, { useState,useEffect } from 'react';
import { FaGear } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";

import "./res.css";
const ResolutionModalButton = ({resolutionSetting, handleResMax, activeResolution}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState(activeResolution); 


  useEffect(()=>{
    if(activeResolution !== selectedResolution){
      const match = activeResolution.match(/\((\d+x\d+)\)/);

      if (match) {
      
      setSelectedResolution(activeResolution);
      handleResMax(match[1],activeResolution);
      
      
      }    }
  },[activeResolution]);


  const openModal = () => {
    setIsModalOpen(true);
  };


 

const handleResolutionSelect = (resolution) => {


const match = resolution.match(/\((\d+x\d+)\)/);

if (match) {

setSelectedResolution(resolution);

setIsModalOpen(false); 
handleResMax(match[1],resolution);


}
  };

  return (
    <>

    
      <button style={styles.button} onClick={openModal}>
      <FaGear size={20}/>
      </button>


 

      {isModalOpen && (

<div className="modal-overlay-res">
<div className="modal-content-res">
  <h3 >Stream Quality</h3>


  <ul className="resolution-list">
    {resolutionSetting.map((resolution) => (
      <li
        key={resolution}
        className={`resolution-item ${
          selectedResolution === resolution ? "selected-resolution" : ""
        }`}
        onClick={() => handleResolutionSelect(resolution)}
      >
        { selectedResolution === resolution? (

        <div style={{display:"flex",gap:"12px",alignItems:"center",padding:"12px"}}>
          <div className='res-icon'><TiTick size={24}/></div>
          <div className='res-text-active'>{resolution}</div>


          </div>
        ):(
          <div style={{display:"flex",gap:"12px",alignItems:"center",padding:"12px"}}>
          <div className='res-text'>{resolution}</div>

         </div>

        )}
      </li>
    ))}
  </ul>

  <button className="close-button" onClick={() => setIsModalOpen(false)}>
    X
  </button>
</div>
</div>

      )}
    </>
  );
};




export default ResolutionModalButton;
const styles = {
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
  },
};